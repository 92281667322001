.lg-backdrop.in {
    opacity: 0.85;
}


.lg-sub-html{
    font-family: var(--font-default-family);
    font-size: 18px;
}
.lg-outer .lg-image {
    border: 0;
}



@media screen and (min-width: 768px) {
    .lg-outer .lg-image {
        border: 40px solid #fff;
    }
}