/* slider for references */

.slick-slider{
    position: relative;
    overflow: hidden;
}



.slick-slider .slick-arrow{
    background: #fff;
    width: 60px;
    height: 60px;
    /* transform: rotate(45deg); */
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    color: var(--color-default);
}

.slick-slider .slick-arrow.slick-prev{
    left: -30px;
}

.slick-slider .slick-arrow.slick-next{
    right: -30px;
}

.slick-slider .slick-arrow.slick-prev .icon{
    transform: rotate(45deg);
    position: relative;
    left: 11px;
    bottom: 8px;
    font-size: 16px;
}

.slick-slider .slick-arrow.slick-next .icon{
    transform: rotate(-135deg);
    position: relative;
    right: 12px;
    top: 15px;
    font-size: 16px;
}

@media screen and (min-width: 768px){
    .slick-slider .slick-arrow{
        background: #fff;
        width: 80px;
        height: 80px;
        /* transform: rotate(45deg); */
        z-index: 2;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
    }

    .slick-slider .slick-arrow.slick-prev{
        left: -40px;
    }

    .slick-slider .slick-arrow.slick-next{
        right: -40px;
    }

    .slick-slider .slick-arrow.slick-prev .icon{
        left: 9px;
        bottom: 5px;
        font-size: 23px;
    }

    .slick-slider .slick-arrow.slick-next .icon{
        right: 11px;
        top: 13px;
        font-size: 23px;
    }
}




/* imgSlider for 2 col wysiwyg with media*/
.slider-nav__item{
    position: relative;
    margin: 1px 1px 0 0;
    cursor: pointer;
}

.slider-nav__item img {
    width: 100%;
}

.item__overlay{
    position: absolute;
    z-index: 1;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: var(--color-primary); /* Old browsers */
    background: linear-gradient(to top, #00d5f4 0%, var(--color-primary) 100%);
}

.slick-current>.item__overlay{
    opacity: 0.8;
}