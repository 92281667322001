.teaser-quer__teaser{
    background: #ffffff;
    margin-bottom: 30px;
}

.teaser-quer__teaser:hover .teaser__img.gradient-primary:before{
    opacity: .8;
}
.teaser-quer__teaser:hover .teaser__triangle-overlay--bottom,
.teaser-quer__teaser:hover .teaser__triangle-overlay--top{
    opacity: 1;
}
.teaser-quer__teaser:hover .default-img__triangle-top,
.teaser-quer__teaser:hover .default-img__triangle-bottom{
    opacity: 0;
}

.teaser-quer__teaser:hover  .default-img__triangle-bottom:before{
    border-bottom: 120px solid #00d5f4;
    opacity: .5;
}
.teaser-quer__teaser:hover  .default-img__triangle-top:before{
    border-top: 120px solid #00d5f4;
    opacity: .5;
}

.teaser-quer__teaser:hover  .default-img__triangle-bottom:after{
    border-bottom: 60px solid #002b90;
    opacity: .2;
}
.teaser-quer__teaser:hover  .default-img__triangle-top:after{
    border-left: 60px solid #002b90;
    opacity: .2;
}


.teaser__triangle-overlay--bottom{
    position: absolute;
    width: 180px;
    height: 163px;
    right: 0;
    bottom: 0;
    opacity: 0;
}

.teaser__triangle-overlay--top{
    position: absolute;
    height: 163px;
    width: 175px;
    left: 0;
    top: 0;
    opacity: 0;
}

.teaser__triangle-overlay--bottom:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-color: transparent;
    opacity: 0.5;
    border-bottom: 120px solid #00d5f4;
    border-left: 120px solid transparent;
    right: 0;
    bottom: 0;
    z-index: 4;
}

.teaser__triangle-overlay--bottom:after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-bottom: 60px solid #002b90;
    opacity: 0.2;
    bottom: 0;
    left:0;
    z-index: 3;
}

.teaser__triangle-overlay--top:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-color: transparent;
    opacity: 0.5;
    border-top: 120px solid #00d5f4;
    border-right: 120px solid transparent;
    left: 0;
    top: 0;
    z-index: 4;
}

.teaser__triangle-overlay--top:after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 60px solid #002b90;
    opacity: 0.2;
    bottom: 0;
    left:0;
    z-index: 3;
}

.teaser__img{
    z-index: 2;
}

.teaser__default-img{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #cbced0;
}

.teaser__default-img.gradient-primary:before,
.teaser__img.gradient-primary:before{
    opacity: 0;
    transition: all 250ms ease;
}

/**/

.teaser__default-img .icon{
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 100px;
    color: #f5f5f6;
}


.default-img__triangle-bottom{
    position: absolute;
    width: 180px;
    height: 163px;
    bottom:0;
    right: 0;
    opacity: 1;
}

.default-img__triangle-bottom:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-color: transparent;
    opacity: 0.15;
    border-bottom: 120px solid #fff;
    border-left: 120px solid transparent;
    right: 0;
    bottom: 0;
    z-index: 2;
}

.default-img__triangle-bottom:after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-bottom: 60px solid #fff;
    opacity: 0.15;
    bottom: 0;
    left:0;
    z-index: 1;
}


.default-img__triangle-top{
    height: 163px;
    width: 175px;
    position: absolute;
    top: 0;
    left: 0;
}

.default-img__triangle-top:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-color: transparent;
    opacity: 0.15;
    border-top: 120px solid #fff;
    border-right: 120px solid transparent;
    left: 0;
    top: 0;
    z-index: 2;
}

.default-img__triangle-top:after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 60px solid #fff;
    opacity: 0.15;
    bottom: 0;
    left:0;
    z-index: 1;
}


.teaser__teaser-body{
    padding: 15px;
}

.teaser-body__hl{
    line-height: 30px;
    font-size: 20px;
    margin-bottom: 5px;
}

.teaser-body__sl{
    font-size: 20px;
    color: #7e848a;
}

.teaser-body__sl>.icon,
h2 .icon.news-detail{
    position: relative;
    top: 1px;
    margin-right: 6px;
}

.teaser-body__description>a{
    color: var(--color-primary);
}
.teaser-body__description>a:hover{
    text-decoration: underline;
}

@media screen and (min-width: 768px) {
    .default-img__triangle-top,
    .teaser__triangle-overlay--top{
        height: 180px;
    }
    .default-img__triangle-bottom,
    .teaser__triangle-overlay--bottom{
        height: 245px;
    }


    .teaser__default-img .icon{
        font-size: 156px;
    }

    .teaser-body__hl{
        line-height: 34px;
        font-size: 24px;
        margin-bottom: 12px;
    }

    .teaser__teaser-body{
        padding: 18px 31px;
    }

    .teaser-quer__teaser{
        margin-bottom: 60px;
    }


}