/* Content Teaser Mobile */
/* _____________________ */

.content-teaser{
    background: #ffffff;
}

/*.content-teaser .gradient-primary:before{
    transition: all 250ms ease;
    opacity: 0;
}

.content-teaser:hover .gradient-primary:before,
.content-teaser:focus .gradient-primary:before{
    opacity: .6;
}*/

.content-teaser__img{
    position: relative;
}

.content-teaser__img .img__hover-overlay{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background: #00d5f4; /* Old browsers */
    background: linear-gradient(to top, #00d5f4 0%, #00abe5 100%);
    transition: all 250ms ease;
    z-index: 1;
}

.content-teaser:hover .content-teaser__img .img__hover-overlay {
    opacity: .8;
}


.content-teaser__img .img__triangle-overlay{
    position: absolute;
    width: 220px;
    height: 155px;
    bottom:0;
    right: 0;
    opacity: 0;
}
.content-teaser:hover .content-teaser__img .img__triangle-overlay{
    opacity: 1;
}

.content-teaser__img .img__triangle-overlay:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-color: transparent;
    opacity: 0.5;
    border-bottom: 150px solid #00d5f4;
    border-left: 150px solid transparent;
    right: 0;
    bottom: 0;
    z-index: 2;
}

.content-teaser__img .img__triangle-overlay:after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-bottom: 60px solid #002b90;
    opacity: 0.2;
    bottom: 0;
    left:0;
    z-index: 1;
}


/* Content Teaser Body */
.content-teaser__body{
    padding: 15px;
}

.content-teaser__body .body__hl{
    font-size: 20px;
    line-height: calc(30/20);
    margin-bottom: 5px;
}

.content-teaser__body .body__description{
    line-height: calc(24/16);
}

.content-teaser__body .body__a{
    color: var(--color-primary);
}

.content-teaser__body .body__a:hover{
    text-decoration: underline;
}

/* Content Teaser Desktop */
/* ______________________ */

@media screen and (min-width: 768px){

    /* Content Teaser Body */
    .content-teaser__body{
        padding: 23px 30px;
    }

    .content-teaser__body .body__hl{
        font-size: 24px;
        line-height: calc(34/24);
        margin-bottom: 10px;
    }

    .content-teaser__body .body__description{
        line-height: calc(33/18);
    }
}