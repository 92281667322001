.table-primary table,
.wysiwyg table{
    width: 100%;
    background: #fff;
    font-size: 16px;
    border: none;
}

.table-primary table>tbody>tr>td,
.table-primary table>thead>tr>th,
.wysiwyg table>tbody>tr>td,
.wysiwyg table>thead>tr>th{
    padding: 20px;
    border:none;
}

.table-primary table>tbody>tr>td,
.wysiwyg table>tbody>tr>td{
    padding: 15px 20px;
    /*border-bottom: 1px solid #d8d8d8;*/
}

.table-primary table th,
.wysiwyg table th {
    color: #fff;
    background: var(--color-primary);
    text-transform: uppercase;
    font-family: var(--font-lato-bold-family);
    font-size: 18px;
}

.table-primary table>tbody>tr>td:last-child,
.wysiwyg table>tbody>tr>td:last-child{
    border-left: 1px solid #bec1c4;
}

.table-primary table>tbody>tr:nth-of-type(odd),
.wysiwyg table>tbody>tr:nth-of-type(odd){
    background: #e6f6fc;
}

.table-primary table>thead>tr>th:last-child,
.table-primary table>tbody>tr>td:last-child{;
    width: 20%;
}


@media screen and (min-width: 768px) and (max-width: 991px){
    .table-primary table>thead>tr>th:last-child,
    .table-primary table>tbody>tr>td:last-child,
    .wysiwyg table>thead>tr>th:last-child,
    .wysiwyg table>tbody>tr>td:last-child{
        width: 20%;
    }
}

@media screen and (max-width: 767px){
    .table-primary table>tbody>tr>td,
    .table-primary table>thead>tr>th,
    .wysiwyg table>tbody>tr>td,
    .wysiwyg table>thead>tr>th{
        padding: 6px;
    }

    .table-primary table>tbody>tr>td:last-child,
    .wysiwyg table>tbody>tr>td:last-child{
        font-size: 14px;
    }

    .table-primary table th,
    .wysiwyg table th{
        font-size: 16px;
    }
}