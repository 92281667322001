.consent-overlay__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-image: linear-gradient(to top,#00d5f4 0,#00abe5 100%);
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: 4rem white solid;
    border-left: 0;
    border-right: 0;

    overflow: hidden;
    display: none;
}

.consent-overlay__wrapper.is-active {
    width: 100%;
    height: 100%;
    display: flex;
}

.consent-overlay__triangles-left {
    height: 100%;
    width: 175px;
    position: absolute;
    top: 0;
    left: 0;
}

.consent-overlay__triangles-left:before {
    border-top: 175px solid #002b90;
    border-right: 175px solid transparent;
    background-color: transparent;

    content: '';
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0.2;
    left: 0;
    top: 0;
    z-index: 1;
}

.consent-overlay__triangles-left:after {
    border-bottom: 275px solid #00d5f4;
    border-right: 275px solid transparent;
    background-color: transparent;

    content: '';
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0.35;
    left: 0;
    bottom: 0;
    z-index: 1;
}

.consent-overlay__triangles-right:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-right: 50px solid #00d5f4;
    opacity: .2;
    bottom: 0;
    right: 0;
    z-index: 1;
    transform: translateY(-200px);
}

.consent-overlay__triangles-right:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 120px solid transparent;
    border-bottom: 120px solid transparent;
    border-right: 120px solid #002b90;
    opacity: .2;
    bottom: 0;
    right: 0;
    z-index: 1;
}

.consent-overlay__wrapper p {
    color: white;
}

.consent-overlay__wrapper p {
    margin-bottom: 1rem;
}

.consent-overlay__wrapper button {
    background-color: transparent;
    font-size: 1.75rem;
    margin-top: 1rem;
}

/* this comes from the cookiebot declaration overlay*/
.CookieDeclaration {
    padding-left: 15px;
    padding-right: 15px;
}