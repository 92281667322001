@media screen and (min-width: 768px){
    .content-video{
        max-width: 995px;
        width: 100%
    }
}

.video-js{
    color: #fff;
}

.vjs-default-skin .vjs-big-play-button{
    left:50%;
    top:50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    width: 150px;
    height: 150px;
    background: #fff;
    color: var(--color-default);
    box-shadow: none;
    border: none;
}

.vjs-default-skin .vjs-big-play-button:focus,
.vjs-default-skin:hover .vjs-big-play-button{
    background-color: #fff;
    box-shadow: 0 0 75px #0a7498;
}

.vjs-default-skin .vjs-big-play-button:before {
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    text-shadow: none;
    font-size: 60px;
    margin-left: 3px;
}

.content-video .vjs-poster{
    position: relative;
    background-size: cover;
}

.content-video .vjs-poster:before{
    content: '';
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-image: linear-gradient(to top, #00d5f4 0%, #00abe5 100%);
    z-index: 1;
    opacity: 0.6;
}

@media screen and (max-width: 767px){
    .video-js.vjs-paused.vjs-using-native-controls .vjs-poster,
    .vjs-default-skin.vjs-paused.vjs-using-native-controls .vjs-big-play-button{
        display: block;
    }

    .vjs-default-skin .vjs-big-play-button{
        width: 80px;
        height: 80px;
    }

    .vjs-default-skin .vjs-big-play-button:before{
        font-size: 40px;
    }

}