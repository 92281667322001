.search-slide .page-heading{
    padding-top: 0;
    padding-bottom: 45px;
}

.search-slide__input{
    position: relative;
}

.search-slide__input .form-control--input{
    padding-right: 110px;
    border: 1px solid #00abe5;
}

.search-slide__input .btn{
    position: absolute;
    right: 0;
    top: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 53px;
    font-size: 16px;
}

.search-result-slide .pagination{
    margin: 0;
}

.search-result-slide .teaser-body__hl{
    font-size: 20px;
    margin-bottom:5px;
}

.search-result-slide .teaser-body__sl{
    font-size: 16px;
    word-break: break-all;
}

.search-result-slide .search-result:first-child .teaser-quer__teaser--search{
    margin-top: 30px;
}

.search-result-slide .search-result b{
    color: var(--color-primary);
}
.search-result-slide .search-result .teaser-body__description b,
.search-result-slide .search-result .teaser-body__sl b{
    font-family: var(--font-default-family);
}
.search-result-slide .search-result .teaser-body__hl b{
    font-family: var(--font-lato-bold-family);
}

@media screen and (min-width: 768px) {
    .search-result-slide .teaser-body__sl{
        font-size: 18px;
    }

    .search-result-slide .search-result:first-child .teaser-quer__teaser--search{
        margin-top: 60px;
    }

    .search-slide__input .btn{
        height: 57px;
        font-size: 20px;
    }
    .search-slide__input .form-control--input{
        padding-right: 130px;
    }
}
