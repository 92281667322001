
.teaser-grid{
    position: relative;
}

.teaser-grid__triangle{
    height: 1000px;
    width: 1000px;
    position: absolute;
    top: 0;
    left: 50px;
}

.teaser-grid__triangle:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-color: transparent;
    border-top: 1000px solid #f2f2f3;
    border-right: 1000px solid transparent;
    left: 0;
    top: 0;
    z-index: 0;
}

@media screen and (min-width: 768px){
    .teaser-grid{
        padding: 80px 0 50px;
    }

    .teaser-grid__triangle{
        height: 800px;
        width: 800px;
        left: 50px;
    }

    .teaser-grid__triangle:before{
        background-color: transparent;
        border-top: 800px solid #f2f2f3;
        border-right: 800px solid transparent;
    }

}

@media screen and (min-width: 1200px){
    .teaser-grid__triangle{
        height: 970px;
        width: 970px;
        left: 100px;
    }

    .teaser-grid__triangle:before{
        background-color: transparent;
        border-top: 970px solid #f2f2f3;
        border-right: 970px solid transparent;
    }
}

@media screen and (max-width: 767px){
    .teaser-grid{
        margin: 60px 0 30px;
    }
}
