.pagination{
    font-size: 20px;
    margin-top: 0;
}

.pagination__previous, .pagination__next{
    font-size: 6px;
    position: absolute;
    right: 0;
    left: 0;
    top: 16px;
}

.pagination>li>a{
    width: 40px;
    height: 40px;
    margin-left: 0;
}

.pagination a.pagination__a--next, .pagination a.pagination__a--previous{
    padding: 0;
}

.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>span:focus,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {
    border-color: #f2f2f3;
}
