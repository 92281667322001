.footer-top__body{
    padding-top: 60px;
    padding-bottom: 60px;
}

.footer__hl{
    margin-bottom: 15px;
    font-size: 22px;
}

.footer__top-triangle{
    position: absolute;
    width: 200px;
    height: 150px;
    bottom: 0;
    left: 0;
    opacity: 1;
}

.footer__top-triangle:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-color: transparent;
    opacity: .2;
    border-bottom: 150px solid #002b90;
    border-right: 150px solid transparent;
    left: 0;
    bottom: 0;
    z-index: 1;
}

.footer__top-triangle:after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 45px solid transparent;
    border-right: 45px solid transparent;
    border-bottom: 45px solid #00d5f4;
    opacity: .5;
    bottom: 0;
    right: 0;
    z-index: 2;
}
.footer__bottom{
    color: var(--color-grey);
    background-color: #f2f2f3;
    padding: 21px 0;
}

.footer__bottom ul {
    margin: 0;
}
.footer__bottom ul>li:first-child{
    padding-left: 0px;
}

.footer__bottom .elements-logo{
    text-align: center;
    margin-top: 21px;
    padding-left: 0;
}
.footer__bottom .elements-logo img{
    height: 15px;
}

.footer__bottom .daikin-logo{
    text-align: center;
    margin: 21px 0px;
    padding: 0;
}

.footer__bottom .list-inline{
    text-align: center;
}

.footer__bottom .list-inline > li{
    padding: 10px 20px;
}
.footer__bottom .list-inline > li > a{
    text-decoration: underline;
}

.footer__bottom .list-inline > li >a:hover{
    color: var(--color-primary);
}



.footer__bottom .icon-daikin{
    padding: 0 20px;
    font-size: 12px;
}

.footer__icon-strauss-filled{
    position: absolute;
    bottom: -1px;
    right: 15px;
    z-index: 1;
    font-size: 74px;
    color: #f2f2f3;
}

@media screen and (min-width: 768px){
    .footer-top__body{
        padding-top: 80px;
        padding-bottom: 80px;
    }


    .footer__hl{
        font-size: 30px;
        margin-bottom: 25px;
    }

    .footer__top .form-group{
        margin-bottom: 0;
    }

    .footer__bottom .list-inline{
        text-align: left;
    }

    .footer__bottom .list-inline > li{
        padding: 0 20px;
    }

    .footer__bottom .icon-daikin{
        padding: 0 10px;
        font-size: 10px;
    }

    .footer__bottom .daikin-logo{
        text-align: left;
        margin: 0;
    }

    .footer__bottom .elements-logo{
        text-align: right;
        margin-top: 0;
    }
    .footer__bottom .elements-logo img{
        height: auto;
    }

    .footer__top-triangle{
        width: 225px;
        height: 150px;
    }

    .footer__top-triangle:after{
        border-left: 85px solid transparent;
        border-right: 85px solid transparent;
        border-bottom: 85px solid #00d5f4;
    }
}
@media screen and (min-width: 1200px){
    .footer__bottom .icon-daikin{
        padding: 0 20px;
        font-size: 12px;
    }
}