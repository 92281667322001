
.heading-block .wysiwyg{
    margin-top: 20px;
    margin-bottom: 40px;
}

.heading-block h2, .page-heading h1{
    margin-bottom: 7px;
}

.page-heading .wysiwyg{
    margin-top: 20px;
}

.heading-block .wysiwyg.intro-text,
.page-heading .wysiwyg.intro-text{
    font-size: 19px;
    line-height: 30px;
}


.page-heading{
    padding: 35px 0;
}
@media screen and (min-width: 768px){

    .heading-block{
        max-width: 1000px;
        margin: 0 auto;
    }

    .page-heading{
        padding: 40px 0 50px 0;
    }

    .heading-block .wysiwyg{
        margin-top: 40px;
        margin-bottom: 80px;
    }

    .page-heading .wysiwyg{
        margin-top: 40px
    }

    .heading-block .wysiwyg.intro-text,
    .page-heading .wysiwyg.intro-text{
        font-size: 22px;
        line-height: 38px;
    }
}