.facts-slide{
    padding: 69px 0;
    position: relative;
}

.facts-slide__triangle-bottom{
    width: 125px;
    height: 85px;
    position: absolute;
    bottom:0;
    right: 0;
}

.facts-slide__triangle-bottom:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-color: transparent;
    opacity: 0.5;
    border-bottom: 85px solid #00d5f4;
    border-left: 85px solid transparent;
    right: 0;
    bottom: 0;
    z-index: 2;
}



.fact-figure{
    font-family: var(--font-lato-bold-family);
    font-size: 40px;
}

.fact-icon{
    font-size: 70px;
}

.fact-text{
    font-size: 20px;
    line-height: 23px;
}

@media screen and (min-width: 768px){
    .facts-slide{
        padding: 65px 0;
    }

    .fact-figure{
        font-size: 50px;
        line-height: 60px;
    }

    .fact-text{
        font-size: 23px;
        line-height: 30px;
    }

    .facts-slide__triangle-bottom{
        width: 250px;
        height: 175px;
    }

    .facts-slide__triangle-bottom:before{
        border-bottom: 175px solid #00d5f4;
        border-left: 175px solid transparent;
    }

    .facts-slide__triangle-bottom:after{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 70px solid transparent;
        border-right: 70px solid transparent;
        border-bottom: 70px solid #002b90;
        opacity: 0.2;
        bottom: 0;
        left:0;
        z-index: 1;
    }


    .facts-slide__triangle-top{
        height: 250px;
        width: 175px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .facts-slide__triangle-top:before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        background-color: transparent;
        opacity: 0.5;
        border-top: 175px solid #00d5f4;
        border-right: 175px solid transparent;
        left: 0;
        top: 0;
        z-index: 2;
    }

    .facts-slide__triangle-top:after{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 70px solid transparent;
        border-bottom: 70px solid transparent;
        border-left: 70px solid #002b90;
        opacity: 0.2;
        bottom: 0;
        left:0;
        z-index: 1;
    }

}

