
.infobox__inner{
    background: #fff;
    box-shadow: 0 0 5px #7e848a;
    position: relative;
    z-index: 0;
}
.infobox__body{
    background: #fff;
    padding: 0 15px;
    text-align: center;
}
.infobox__hl{
    font-size: 18px;
    line-height: 1;
}

.infobox__link{
    font-size: 15px;
    text-decoration: underline;
    margin-top: 12px;
    display: block;
}

.google-map__marker .marker-plus{
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    display: block;
    left: 6px;
    top: 6px;
}

.google-map__marker .marker-plus:before{
    content: var(--icon-plus);
    font-family: iconfont;
    position: absolute;
    display: block;
    font-size: 11px;
    color: var(--color-default);
    top: 4px;
    left: 5px;
}

.google-map__marker-wrapper.is-active .icon-poi{
    color: var(--color-secondary);
}

.google-map__marker-wrapper.is-active .marker-plus:before{
    transform: rotate(45deg);
}