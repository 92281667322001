
.reference-slider-img__big{
    position: relative;
}

.reference-slider-img__big:before{
    content: '';
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right: 0;
    background-image: linear-gradient(to top, #00d5f4 0%, #00abe5 100%);
    opacity: 0.8;
    z-index: 1;
}

.reference-slider__body{
    position: absolute;
    left:0;
    right: 0;
    top:50%;
    transform: translateY(-50%);
    z-index: 2;
    color: #fff;
}

.reference-slider__body .btn{
    margin-top: 15px;
}
.reference-slider__body .btn:hover{
    color: var(--color-primary);
}

.reference-slider-item__hl{
    font-size: 27px;
}

.reference-logo img{
    display: inline-block;
}

.reference-logo{
    margin-bottom: 15px;
}

@media screen and (min-width: 768px){
    .reference-slider-img__small{
        margin-top: 52px;
    }

    .reference-slider-item__sl{
        font-size: 23px;
        text-transform: uppercase;
    }

    .reference-slider-item__hl{
        font-size: 45px;
    }

    .reference-slider__body .btn{
        margin-top: 25px;
    }

    .reference-logo{
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 768px){
    .reference-slider__body{
        padding: 0 50px;
    }
}