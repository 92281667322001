body {
    position: relative;
    background: #EBECED;
}
.page-wrapper {
    background: #fff;
    position: relative;
}

.has-overflow-hidden .page-wrapper:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    z-index: 5;
    content: '';
}

.container {
    max-width: 1230px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    position: relative;
    z-index: 3;
}

.container-outer,
.container-fluid{
    width: 100%;
    max-width: 1440px;
    margin: auto;
    background: #fff;
    position: relative;
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    body {
        width: 982px;
    }
    .container-outer{
        overflow: hidden;
    }
}

@media screen and (max-width: 767px) {
    body {
        min-width: 320px;
        max-width: 100%;
        overflow-x: hidden;
    }
    html {
        overflow-x: hidden;
    }
}