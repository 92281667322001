.info-modal__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.info-modal__title {
    color: var(--color-primary);
}

.info-modal__close-button {
    font-size: calc(48rem /16);
}

.modal-open .info-modal {/*bugfix for IE -> this code is needed because the .fade class does not work for IE, see https://stackoverflow.com/questions/16329980/modal-not-opening-in-ie*/
    display: block;
}