.btn-no-style{
    background: none;
    border:none;
    box-shadow: none;
}
.btn-no-style:hover,
.btn-no-style:focus{
    color: inherit;
}

.btn-secondary{
    background: var(--color-secondary);
    color: #fff;
}

.btn-outline{
    border: 2px solid #fff;
    color: #fff;
}

.btn-primary,
.btn-primary:hover,
.btn-secondary:hover,
.btn-outline:hover,
.btn-secondary:focus,
.btn-outline:focus{
    color: #fff !important;
    text-decoration: none !important;
}


.btn-white{
    background: #fff;
    color: var(--color-default);
}

.btn-icon .icon{
    font-size: 16px;
    margin-right: 10px;
    position: relative;
    top: 1px;
}

@media screen and (min-width: 768px){
    .btn.btn-outline{
        padding: 15px 25px;
    }
}


/* btn hover effect */
.btn-primary,
.btn-secondary,
.btn-outline{
    overflow: hidden;
    position: relative;
}

.btn-primary:before,
.btn-secondary:before,
.btn-outline:before{
    background: #fff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: 10;
}
.btn-primary:hover:before,
.btn-secondary:hover:before,
.btn-outline:hover:before{
    left: 120%;
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}


@media screen and (max-width: 767px){
    .btn-request{
        width: 100%;
        margin-top: 20px;
    }
}

.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
}