@media screen and (min-width: 768px){
    .navbar-container{
        padding: 0 45px;
    }
    .navbar-collapse{
        position: relative;
    }

    .navbar-main{
        height: 100px;
        background: transparent;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 5;
        color: #fff;
        padding: 21px 0;
        transition: all 150ms ease;

    }

    .affix.navbar-main {
        position: fixed;
        background: #fff;
        color: var(--color-default);
        border-bottom: 1px solid #bec1c4;
        transform: translateZ(0);
        z-index: 10;
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        transition: all 150ms ease;
    }


    .navbar-main .navbar-nav>li{
        position: relative;
    }

    /*.navbar-main .navbar-nav>li>a:hover,*/
    /*.navbar-main .navbar-nav>li>a:focus{*/
        /*font-weight: 700;*/
    /*}*/


    .navbar-right{
        font-size: 20px;
        position: absolute;
        right: 15px;
    }
    .navbar-right>li:last-child{
        padding-right: 0;
    }

    .navbar-right__item{
        display: block;
        color: #fff;
        padding: 18px 8px;
        line-height: 20px;
        vertical-align: middle;
    }

    .navbar-right__item--search{
        position: relative;
        width: 105px;
        height: 57px;
        vertical-align: middle;
        top: -3px;
    }
    .navbar-right__item--search .form-wrap{
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        right: 0;

    }

    .affix .navbar-right__item{
        color: var(--color-primary);
        transition: all 150ms ease;
    }

    .navbar-right__item .icon {
        font-size: 18px;
        position: relative;
        top: 3px;
        margin-right: 2px;
    }

    .navbar-right__item .icon.icon-mail{
        font-size: 14px;
        top: 1px;
        margin-right: 7px;
    }

    .navbar-right__item .icon.icon-search{
        margin-right: 4px;
    }

    .navbar-main__dropdown{
        position: absolute;
        display: none;
        padding: 0;
        left: 50%;
        transform: translateX(-50%);
        border-top: 10px solid transparent;
        width: auto;
        min-width: 140px;
    }

    .navbar-main .navbar-nav>li:hover .navbar-main__dropdown{
        display: block;
    }

    .navbar-main__dropdown ul{
        background: #fff;
        color: var(--color-default);
    }
    
    .affix .navbar-main__dropdown ul{
        background-image: linear-gradient(to top, #00d5f4 0%, #00abe5 100%);
        color: #fff;
    }

    .navbar-main__dropdown:before{
        content: '';
        position: absolute;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 15px solid #fff;
        top: -15px;
        left: 50%;
        transform: translateX(-50%);
    }

    .affix .navbar-main__dropdown:before{
        border-bottom: 15px solid #00abe5;
    }

    .navbar-main__dropdown ul>li>a{
        white-space: nowrap;
        padding: 9px 20px;
        display: block;
        border-bottom: 1px solid #bec1c4;
    }

    .navbar-main__dropdown ul>li:last-child>a,
    .affix .navbar-main__dropdown ul>li:last-child>a{
        border-bottom: none;
    }

    .affix .navbar-main__dropdown ul>li>a{
        border-bottom: 1px solid #fff;
    }

    .navbar-main__dropdown ul>li>a:hover{
        color: var(--color-primary);
    }

    .affix .navbar-main__dropdown ul>li>a:hover{
        color: #fff;
        background: #00abe5;
    }

    .navbar-main__dropdown ul>li.has-subnav{
        position: relative;
    }

    .navbar-main__dropdown__subnav{
        position: absolute;
        left: 100%;
        top: 0;
        width: 100%;
        display: none;
        background: var(--color-primary);
        border-left: 1px solid #bec1c4;
    }

    .affix .navbar-main__dropdown__subnav{
        border-left: 1px solid #fff;
    }

    .navbar-main__dropdown ul>li:hover .navbar-main__dropdown__subnav{
        display: block;
    }

    .navbar-main__dropdown ul>li.has-subnav>a:before{
        content: var(--icon-arrow-nav);
        font-family: iconfont;
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -5px;
        transform: rotate(-90deg);
        display: block;
        font-size: 5px;
    }


    /* toggle search */
    .navbar-right__searchbar {
        position: relative;
        transition: margin 250ms ease;
    }
    .navbar-right>li{
        transition: all 250ms ease;
    }

    .navbar-nav{
        transition: opacity 250ms ease;
    }

    .navbar-right__search-input{
        transition: all 250ms ease;
        width: 0;
        border: 0;
        height: 0;
        opacity: 0;
        padding: 0;
    }
    .navbar-search-trigger{
        height: 57px;
        color: #fff;
        transition: all 250ms ease;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -15px;
    }

    .navbar-right .search-close{
        color: #fff;
        cursor: pointer;
        transition: all 250ms ease;
        position: absolute;
        font-size: 25px;
        top: 17px;
        bottom: 0;
        right: -80px;
        display: block;
        height: 25px;
        transform: rotate(45deg);
        opacity: 0;
    }

    /* Navbar active */

    .navbar-main.search-active a.navbar-brand{
        border: 2px solid #fff;
        margin-top: -2px;
        margin-left: -2px;
    }

    .navbar-main.search-active .navbar-right__item--search{
        margin-right: 70px;
    }
    .navbar-main.search-active .navbar-right .search-close{
        transform: rotate(-135deg);
        opacity: 1;
    }

    .navbar-main.search-active.affix-top{
        background: var(--color-primary);
    }
    .navbar-main.search-active .navbar-right>li:not(:last-child){
        opacity: 0;
    }
    .navbar-main.search-active .navbar-nav{
        opacity: 0;
    }

    .navbar-main.search-active .navbar-search-trigger{
        margin-right: 0;
        background: #fff;
        color: var(--color-primary);
        border-radius: 0 1000px 1000px 0;
    }

    .navbar-main.search-active .navbar-right__search-input{
        padding: 15px 120px 15px 20px;
        opacity:1;
        width: 585px;
        border: 1px solid white;
    }

    .navbar-main.search-active.affix .navbar-right__search-input{
        border: 1px solid #00abe5;
        color: #999;
    }
    .navbar-main.search-active.affix .navbar-search-trigger{
        background: var(--color-primary);
        color: #fff;
    }

    .navbar-main.search-active.affix .navbar-right .search-close{
        color: var(--color-primary);
    }


    /* Placeholder color for nav affix */
    .navbar-main.search-active.affix .navbar-right__search-input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: #999;
    }
    .navbar-main.search-active.affix .navbar-right__search-input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: #999;
        opacity:  1;
    }
    .navbar-main.search-active.affix .navbar-right__search-input::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: #999;
        opacity:  1;
    }
    .navbar-main.search-active.affix .navbar-right__search-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #999;
    }


}




@media screen and (min-width: 768px) and (max-width: 1199px){
    .navbar-nav>li>a{
        font-size: 18px;
        padding: 16px 9px;
    }
    .navbar-right__item {
        padding: 16px 8px;
    }

    .navbar-container{
        padding: 0 15px;
    }
}


@media screen and (min-width:1200px) and (max-width: 1500px){
    .navbar-nav>li>a{
        padding-left:13px;
        padding-right:13px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1420px){
    .navbar-right{
        position: absolute;
        right: 0;
        margin-right: 5px;
    }

    .navbar-right__item-text{
        display: none;
    }

    .navbar-right__item .icon.icon-mail{
        margin-right: 0;
    }

    .navbar-right.list-inline>li{
        padding-left: 0;
        padding-right: 0;
    }
    .navbar-right__item--search{
        width: 35px;
    }
    .navbar-main.search-active .navbar-right__item--search{
        margin-right: 60px;
    }

    .navbar-main.search-active .navbar-right__search-input{
        padding: 15px 50px 15px 20px;
        width: 370px;
    }
    .navbar-main.search-active .navbar-right .search-close{
        right: -65px;
    }

}
@media screen and (max-width: 767px){
    .navbar-header__tel{
        font-size: 30px;
        line-height: 1;
        padding: 14px 15px;
        color: var(--color-primary);
    }

    .navbar.no-header-image{
        border-bottom-color: #f2f2f3;
    }
}