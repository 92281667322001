.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #212529;
    color: #ffffff;
    padding: .75rem 1rem;
    z-index: 99;
}
@media screen and (min-width: 768px) {
    .cookie-bar {
        display: flex;
        align-items: baseline;
    }
}
.cookie-bar__text {
    display: inline;
}
@media screen and (min-width: 768px) {
    .cookie-bar__text {
        display: block;
        flex: 0 1 auto;
    }
}

.cookie-bar__detail,
a.cookie-bar__detail {
    display: inline;
}
@media screen and (min-width: 768px) {
    .cookie-bar__detail {
        flex: auto;
        margin-left: 1rem;
    }
}
.cookie-bar__detail-btn {
    display: inline;
    text-decoration: underline;
    vertical-align: inherit;
    cursor: pointer;
    margin-right: .25em;
    color: #00abe5;
}
.cookie-bar__detail-btn:hover,
.cookie-bar__detail-btn:focus,
.cookie-bar__detail-btn:active {
    color: #ffffff;
}
.cookie-bar__detail-btn:hover {
    text-decoration: none;
}
a.cookie-bar__detail-btn:hover {
    color: #ffffff;
}
.cookie-bar__buttons {
    margin-top: .75rem;
    text-align: right;
}
@media screen and (min-width: 768px) {
    .cookie-bar__buttons {
        flex: none;
        margin-left: 1rem;
    }
}
.cookie-bar__accept {
    margin-left: .75rem;
}

/* modal */
.cookie-modal__item {
    margin-bottom: .75rem;
}
.cookie-modal__item--all {
    margin-bottom: 1rem;
}
.cookie-modal__label {
    font-size: 1.5rem;
    /*font-weight: bold;*/
}
.cookie-modal__link {
    display: inline-block;
    margin-left: .5rem;
    text-decoration: underline;
}

/* switch */
.cookie-modal__switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    vertical-align: middle;
    margin-right: .5rem;
}
.cookie-modal__switch-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}
.cookie-modal__switch-toggle:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 34px;
}
input:checked + .cookie-modal__switch-toggle {
    background-color: var(--color-primary);
}
input:focus + .cookie-modal__switch-toggle {
    box-shadow: 0 0 2px var(--color-primary);
}
input:checked + .cookie-modal__switch-toggle:before {
    transform: translateX(26px);
}

.dsgvo-hint {
    margin-top: 30px;
}