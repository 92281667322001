/* USP Slide  Mobile */
/* _________________ */

/*.usp-slide{*/
    /*border-bottom: 1px solid #e0e0e2;*/
/*}*/

.usp-item{
     padding: 0 15px;
}

.usp-item__icon{
    font-size: 92px;
    color: var(--color-primary);
    margin-bottom: 13px;
}

.usp-item__hl{
    margin-bottom: 6px;
}

.usp-item__description{
    padding: 0;
    margin-bottom: 6px;
}

.usp-item__a{
    color: var(--color-primary);
    text-decoration: underline;
}

/* USP Slide  Desktop */
/* __________________ */

@media screen and (min-width: 768px){
    .usp-item__description{
        padding: 0;
        margin-bottom: 0;
    }

    .usp-item__icon{
        font-size: 75px;
        margin-bottom: 20px;
        transition: transform 350ms cubic-bezier(.68,-.55,.27,1.55);
    }

    .usp-item:hover .usp-item__icon{
        transform: translateY(-15px);
    }
}

@media screen and (min-width: 992px){
    .usp-item__description{
        padding: 0 30px;
    }
}
