.routenplaner{
    position: relative;
    margin-bottom: 50px;
}
.routenplaner .btn{
    position: absolute;
    right: 0;
    top:0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 57px;
}

.routenplaner .form-control{
    padding-right: 170px;
    border: 1px solid #00abe5;
}

@media screen and (max-width: 767px){
    .routenplaner .btn{
        height: 53px;
        font-size: 16px;
        padding: 10px;
    }

    .routenplaner .form-control{
        padding-right: 115px;
    }
}