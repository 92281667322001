.wysiwyg-media a,
.wysiwyg a {
    color: var(--color-primary);
}
.wysiwyg-media a:hover,
.wysiwyg-media a:focus,
.wysiwyg-media a:active,
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}

.wysiwyg ul{
    list-style: none;
    padding-left: 20px;
}

.wysiwyg ul>li{
    position: relative;
    padding-left: 25px;
}

.wysiwyg ul>li:before{
    content: '';
    width: 8px;
    height: 8px;
    background: var(--color-primary);
    position: absolute;
    left:0;
    top:11px;
}

.wysiwyg-media{
    line-height: 1.8;
}

.wysiwyg-media__text, .wysiwyg-media__media{
    padding: 0 16px;
}

.wysiwyg-media__floated-img .img-floated{
    margin-bottom: 15px;
}

.media__img-lightbox{
    position: relative;
    overflow: hidden;
}

.media__img-lightbox .img-lightbox__btn{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.media__img-lightbox .img-lightbox__icon{
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.img-lightbox__btn{
    opacity: 1;
    transform: translateX(-15px) translateY(-15px);
    transition: all 250ms ease;
}

.media__img-lightbox:hover .img-lightbox__btn{
    opacity: 1;
    transform: translateX(-15px) translateY(-15px);
}

@media screen and (min-width: 768px) {
    .img-lightbox__btn{
        transform: translateX(55px) translateY(55px);
    }
    .media__img-lightbox .img-lightbox__btn{
        width: 57px;
        height: 57px;
    }

    .wysiwyg-media__floated-img .img-floated--right{
        margin: 5px 0 30px 30px;
    }

    .wysiwyg-media__floated-img .img-floated--left{
        margin: 5px 30px 30px 0;
    }
}

@media screen and (max-width: 768px){
    .wysiwyg-media__media{
        margin-top: 20px;
    }
}