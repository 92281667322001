.form-control{
    border: 1px solid #bec1c4;
    border-radius: 1000px;
    box-shadow: none;
    padding: 15px 20px;
    color: var(--color-grey);
}

@media screen and (min-width: 768px){
    .form-control{
        height: 57px;
        font-size: 20px;
    }
}

.form-control.form-control--transparent{
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
}

.form-control.form-control--textarea{
    border-radius: 28px;
}



.form-control.form-control--transparent::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #fff;
    font-size: 16px;
}
.form-control.form-control--transparent:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    #fff;
    font-size: 16px;
    opacity:  1;
}
.form-control.form-control--transparent::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    #fff;
    font-size: 16px;
    opacity:  1;
}
.form-control.form-control--transparent:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:    #fff;
    font-size: 16px;
}

@media screen and (min-width: 768px){

    .form-control.form-control--transparent::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        font-size: 20px;
    }
    .form-control.form-control--transparent:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        font-size: 20px;
        opacity:  1;
    }
    .form-control.form-control--transparent::-moz-placeholder { /* Mozilla Firefox 19+ */
        font-size: 20px;
        opacity:  1;
    }
    .form-control.form-control--transparent:-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-size: 20px;
    }
}


select.form-control{
    appearance: none;
    -moz-appearance:none;
    -webkit-appearance:none;
}

select.form-control::-ms-expand {
    display: none;
}

.select {
    position: relative;
}
.select:before {
    content: var(--icon-arrow-dropdown);
    font-family: iconfont;
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: 2px;
    background: transparent;
    pointer-events: none;
    font-size: 6px;
    padding: 21px 20px;
    color: var(--color-grey);
}

.select.select--transparent:before {
    color: #fff;
}
select.form-control option{
    color: var(--color-default);
}


@media screen and (min-width: 768px){

    .select:before {
        padding: 22px 20px;
    }


    .request-form .form-group{
        margin-bottom: 40px;
    }
}


.checkbox-wrap input[type=checkbox] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.checkbox-wrap label {
    padding-left: 23px;
    user-select: none;
}

.checkbox-wrap label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 17px;
    height: 17px;
    background: #fff;
    border: 1px solid #bec1c4;
    border-radius: 5px;
}

.checkbox-wrap input[type=checkbox]+label:after {
    content: var(--icon-check);
    font-family: iconfont;
    position: absolute;
    left: 4px;
    top: 9px;
    width: 17px;
    height: 17px;
    font-size: 9px;
    color: var(--color-grey);
    opacity: 0;
}

.checkbox-wrap input[type=checkbox]:checked+label:after {
    opacity: 1;
}

.checkbox--agb a{
    color: var(--color-primary);
}

/* errors */
.parsley-errors-list {
    list-style-type: none;
    padding: 0;
    margin:0;
    color:red;
}