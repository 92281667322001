.accordion {

}

.accordion .panel {
    border: 0;
    border-radius: 0;
    box-shadow: none;
}
.accordion .panel-body {
    border: 0 !important;
    padding:18px;
}
.accordion .panel-heading {
    padding:0;
    border: 0;
    border-radius: 0;
    background: transparent;
}
.accordion .panel-heading a {
    position: relative;
    display: block;
    font-size:16px;
    padding:18px 65px 18px 18px;
    background-color: #f5f5f5;
}
.accordion .panel-heading a:not(.collapsed),
.accordion .panel-heading a:hover,
.accordion .panel-heading a:focus {
    background-color: #ebebeb;
}
.accordion .panel-heading a > .icon {
    display: block;
    position: absolute;
    right:20px;
    top:20px;
    transform: rotate(45deg);
    transition: all 250ms ease;
}
.accordion .panel-heading a.collapsed > .icon {
    transform: rotate(0);
}


@media screen and (min-width: 768px){
    .accordion .panel-heading a {
        font-size:18px;
        padding:18px 65px 18px 25px;
    }
    .accordion .panel-body {
        padding:25px;
    }
}