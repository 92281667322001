.slide{
    padding: 35px 0;
    position: relative;
    overflow: hidden;
}

.slide--content__heading{
    margin-bottom: 20px;
}

.slide--grey{
    background: #f2f2f3;
}

.slide .container{
    z-index: 5;
    position: relative;
}



.slide__triangle-bottom{
    position: absolute;
    width: 300px;
    height: 200px;
    bottom:0;
    right: 0;
}

.slide__triangle-bottom:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-color: transparent;
    opacity: 0.05;
    border-bottom: 200px solid #7e848a;
    border-left: 200px solid transparent;
    right: 0;
    bottom: 0;
    z-index: 2;
}


.slide__triangle-bottom:after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    border-bottom: 100px solid #7e848a;
    opacity: 0.05;
    bottom: 0;
    left:0;
    z-index: 1;
}


.slide__triangle-top{
    position: absolute;
    width: 100px;
    height: 300px;
    left:0;
    top: 0;
}

.slide__triangle-top:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 140px solid transparent;
    border-bottom: 140px solid transparent;
    border-left: 140px solid #7e848a;
    opacity: .05;
    left: 0;
    top: -70px;
    z-index: 2;
}


.slide__triangle-top:after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 100px solid transparent;
    border-bottom: 100px solid transparent;

    border-left: 100px solid #7e848a;
    opacity: 0.05;

    bottom: 0;
    left:0;
    z-index: 1;
}

@media screen and (min-width: 768px){
    .slide{
        padding: 55px 0;
    }
    .slide--content__heading{
        margin-bottom: 45px;
    }

    .slide__triangle-bottom{
        width: 600px;
        height: 400px;
    }

    .slide__triangle-bottom:before{
        background-color: transparent;
        opacity: 0.05;
        border-bottom: 400px solid #7e848a;
        border-left: 400px solid transparent;
    }


    .slide__triangle-bottom:after{
        border-left: 200px solid transparent;
        border-right: 200px solid transparent;
        border-bottom: 200px solid #7e848a;
    }


    .slide__triangle-top{
        width: 300px;
        height: 620px;
    }

    .slide__triangle-top:before{
        border-top: 280px solid transparent;
        border-bottom: 280px solid transparent;
        border-left: 280px solid #7e848a;
        top: -140px;
    }


    .slide__triangle-top:after{
        border-top: 200px solid transparent;
        border-bottom: 200px solid transparent;

        border-left: 200px solid #7e848a;
    }
}