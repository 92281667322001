.text-primary{ color: var(--color-primary); }
.text-default{ color: var(--color-default); }
.text-grey{ color: var(--color-grey); }
.text-white{ color: #fff; }

.bg-primary{ background: var(--color-primary); }
.bg-grey{ background: var(--color-grey); }
.bg-white{ background: #fff; color: var(--color-default); }


.font-sans-regular, .font-default{ font-family: var(--font-default-family); }
.font-lato-bold{ font-family: var(--font-lato-bold-family); }
.font-sans-bold{ font-family: var(--font-sans-bold-family); }


.no-margin { margin: 0; }
/*margins*/
.mt10 {margin-top: 10px;}
.mt15 {margin-top: 15px;}
.mt20 {margin-top: 20px;}
.mt30 {margin-top: 30px;}

.mb0{margin-bottom: 0px; }
.mb10{margin-bottom: 10px; }
.mb15{margin-bottom: 15px; }
.mb20{margin-bottom: 20px; }
.mb30{margin-bottom: 30px; }
.mr5 {margin-right: 5px;}


a.skiplink {
    position: fixed;
    left: -10000px;
    top: -10000px;
    z-index: 1000;
    font-size: 14px;
    padding: 3px;
}
a.skiplink:focus {
    left: 0;
    top: 0;
    outline: none;
    background-color: var(--color-primary);
    color: #fff;
}

.isClickable, .isCursor {cursor: pointer;}
.d-ib {display: inline-block;}
.d-b {display: block;}

.centered{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.gradient-primary{
    position: relative;
}

.gradient-primary:before{
    content: '';
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-image: linear-gradient(to top, #00d5f4 0%, #00abe5 100%);
    z-index: 1;
    opacity: 0.8;
}

.gradient-primary__body{
    position: relative;
    z-index: 3;
}

.img-grayscale{
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

[hidden] {
    display: none !important;
}