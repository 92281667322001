body {
    font-size: 16px;
    color: var(--color-default);
    line-height: calc(24 / 16);
    font-family: var(--font-default-family);
    font-weight: 400;
}
@media screen and (min-width: 768px) {
    body {
        line-height: calc(33 / 18);
        font-size: 18px;
    }
}

.font-bold, strong, b {
    font-family: var(--font-sans-bold-family);
    font-weight: normal;
}

.font-shadow{
    text-shadow: 0px 0px 4px rgba(0,0,0,0.4);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
    color: inherit;
}
h1, .h1 {
    font-size: 32px;
    line-height: calc(45/35);
    font-family:var(--font-lato-bold-family);
    text-transform: uppercase;
}
h2, .h2 {
    font-size: 28px;
    line-height: calc(40/30);
    font-family:var(--font-lato-bold-family);
    text-transform: uppercase;
}
h3, .h3 {
    font-size:22px;
    line-height: calc(30/22);
    font-family:var(--font-default-family);
    text-transform: uppercase;
}
h4, .h4 {
    font-size: 18px;
    line-height: calc(22/18);
    font-family:var(--font-default-family);
    text-transform: uppercase;
}


@media screen and (min-width: 768px) {
    h1, .h1 {
        font-size: 55px;
        line-height: calc(72/60);
    }
    h2, .h2 {
        font-size: 40px;
        line-height:calc(66/55);
    }
    h3, .h3 {
        font-size: 24px;
        line-height:calc(37/26);
    }
    h4, .h4 {
        font-size: 21px;
        line-height:calc(26/21);
    }

}
