.header-sujet,
.header-image{
    position: relative;
    color: #fff;
    overflow: hidden;
}

.navbar.no-header-image~.page-wrapper>main>.header-image{
    display:none;
}

.header-top-gradient:after{
    content: '';
    position: absolute;
    top: 0;
    left:0;
    right: 0;
    z-index: 2;
    height: 230px;
    background: linear-gradient(to bottom,  rgba(0,43,143,0.5) 0%,rgba(0,43,143,0.1) 72%,rgba(0,43,143,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

.header-sujet__content{
    position: absolute;
    z-index: 3;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
}

.header-scroll-down{
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 43px solid transparent;
    border-right: 43px solid transparent;
    border-bottom: 43px solid #fff;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
}
.navbar.no-header-image~.page-wrapper>main>.header-image>.header-scroll-down{
    display:none;
}


.header-scroll-down .icon{
    color: var(--color-default);
    font-size: 20px;
    position: absolute;
    top: 18px;
    left: -10px;
    transition: transform 250ms ease;
}

.header-scroll-down:hover .icon{
    transform: translateY(5px);
}

.header-sujet__triangle-bottom{
    width: 203px;
    height: 90px;
    position: absolute;
    bottom:0;
    right: 0;
}

.header-sujet__triangle-bottom:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-color: transparent;
    opacity: 0.5;
    border-top: 180px solid transparent;
    border-bottom: 180px solid transparent;
    border-right: 180px solid #00d5f4;
    right: 0;
    bottom: 130px;
    z-index: 2;
}

.header-sujet__triangle-bottom:after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 90px solid transparent;
    border-right: 90px solid transparent;
    border-bottom: 90px solid #002b90;
    opacity: 0.2;
    bottom: 0;
    left:0;
    z-index: 1;
}


.header-sujet__triangle-top{
    height: 285px;
    width: 140px;
    position: absolute;
    top: 0;
    left: 0;
}

.header-sujet__triangle-top:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-color: transparent;
    opacity: 0.5;
    border-top: 140px solid transparent;
    border-bottom: 140px solid transparent;
    border-left: 140px solid #00d5f4;
    left: 0;
    top: -60px;
    z-index: 2;
}

.header-sujet__triangle-top:after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 60px solid #002b90;
    opacity: 0.2;
    bottom: 0;
    left:0;
    z-index: 1;
}

@media screen and (min-width: 1200px){
    .header-sujet__triangle-bottom{
        width: 791px;
        height: 560px;
    }

    .header-sujet__triangle-bottom:before{

        border-top: 180px solid transparent;
        border-bottom: 180px solid transparent;
        border-right: 180px solid #00d5f4;
        bottom: 130px;
    }

    .header-sujet__triangle-bottom:after{
        border-left: 550px solid transparent;
        border-right: 550px solid transparent;
        border-bottom: 550px solid #002b90;
    }


    .header-sujet__triangle-top{
        height: 685px;
        width: 400px;
    }

    .header-sujet__triangle-top:before{
        border-top: 400px solid transparent;
        border-bottom: 400px solid transparent;
        border-left: 400px solid #00d5f4;
        top: -260px;
    }

    .header-sujet__triangle-top:after{
        border-top: 180px solid transparent;
        border-bottom: 180px solid transparent;
        border-left: 180px solid #002b90;
    }
}

@media screen and (min-width: 768px){
    .navbar.no-header-image~.page-wrapper>main>.header-image{
        display:block;
        height: 100px;
    }

    .header-sujet__pre-hl{
        font-size: 30px;
    }

    .header-sujet__hl{
        font-size: 75px;
        line-height: 90px;
    }

    .header-sujet__btn-wrapper{
        margin-top: 40px;
    }

    .header-sujet__btn-wrapper .btn{
        margin-right: 20px;
    }

    .header-scroll-down{
        border-left: 71px solid transparent;
        border-right: 71px solid transparent;
        border-bottom: 71px solid #fff;
    }

    .header-scroll-down .icon{
        font-size: 30px;
        top: 28px;
        left: -15px;
    }

    .header-sujet__triangle-bottom{
        width: 550px;
        height: 450px;
    }

    .header-sujet__triangle-bottom:before{

        border-top: 120px solid transparent;
        border-bottom: 120px solid transparent;
        border-right: 120px solid #00d5f4;
        bottom: 70px;
    }

    .header-sujet__triangle-bottom:after{
        border-left: 450px solid transparent;
        border-right: 450px solid transparent;
        border-bottom: 450px solid #002b90;
    }


    .header-sujet__triangle-top{
        height: 530px;
        width: 300px;
    }

    .header-sujet__triangle-top:before{
        border-top: 300px solid transparent;
        border-bottom: 300px solid transparent;
        border-left: 300px solid #00d5f4;
        top: -180px;
    }

    .header-sujet__triangle-top:after{
        border-top: 120px solid transparent;
        border-bottom: 120px solid transparent;
        border-left: 120px solid #002b90;
    }


    .fade-in-1:before{
        animation: animationFramesBefore ease 1s;
        animation-iteration-count: 1;
        transform-origin: 50% 50%;
        /*animation-delay: 0.2s;*/
        /*animation-fill-mode:forwards; !*when the spec is finished*!*/
        display: block;
    }

    .fade-in-1:after{
        animation: animationFramesAfter ease 1s;
        animation-iteration-count: 1;
        transform-origin: 50% 50%;
        /*animation-delay: 0.5s;*/
        animation-duration: 3s;
        /*animation-fill-mode:forwards; !*when the spec is finished*!*/
        display: block;
    }

    @keyframes animationFramesBefore{
        0% {
            opacity:0;
            transform:  translate(-30px,-0)  ;
        }
        100% {
            opacity:0.5;
            transform:  translate(0px,0px)  ;
        }
    }

    @keyframes animationFramesAfter{
        0% {
            opacity:0;
            transform:  translate(-30px,-0)  ;
        }
        100% {
            opacity:0.2;
            transform:  translate(0px,0px)  ;
        }
    }


    .fade-in-2:before{
        animation: animationFramesBefore2 ease 1s;
        animation-iteration-count: 1;
        transform-origin: 50% 50%;
        /*animation-delay: 0.2s;*/
        /*animation-fill-mode:forwards; !*when the spec is finished*!*/
        display: block;
    }

    .fade-in-2:after{
        animation: animationFramesAfter2 ease 1s;
        animation-iteration-count: 1;
        transform-origin: 50% 50%;
        /*animation-delay: 0.5s;*/
        animation-duration: 3s;
        /*animation-fill-mode:forwards; !*when the spec is finished*!*/
        display: block;
    }

    @keyframes animationFramesBefore2{
        0% {
            opacity:0;
            transform:  translate(30px,-0)  ;
        }
        100% {
            opacity:0.5;
            transform:  translate(0px,0px)  ;
        }
    }

    @keyframes animationFramesAfter2{
        0% {
            opacity:0;
            transform:  translate(0,30px)  ;
        }
        100% {
            opacity:0.2;
            transform:  translate(0px,0px)  ;
        }
    }


}

@media screen and (max-width: 767px){
    .header-sujet__triangle-bottom:before{
        border-bottom: 90px solid #00d5f4;
        border-left: 90px solid transparent;
        border-top:none;
        border-right: none;
        bottom:0;
    }
}

