.img-teaser{
    position: relative;
    overflow: hidden;
}

.img-teaser__body{
    position: absolute;
    top: 50%;
    left:0;
    right: 0;
    transform: translateY(-50%);
    z-index: 2;
}

.img-teaser__body .btn{
    margin-top: 15px;
}

.img-teaser__overlay-text-readable{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(1,1,1,0.3);
    z-index: 1;
    transition: all 250ms ease;
}
.img-teaser:hover .img-teaser__overlay-text-readable{
    opacity: 0;
}
.img-teaser__hover-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background: #00d5f4; /* Old browsers */
    background: linear-gradient(to top, #00d5f4 0%, #00abe5 100%);
    transition: all 250ms ease;
    z-index: 1;
}
.img-teaser:hover .img-teaser__hover-overlay {
    opacity: .8;
}

/*.img-teaser__hover-overlay:before {*/
    /*content: '';*/
    /*position: absolute;*/
    /*width: 0;*/
    /*height: 0;*/
    /*background-color: transparent;*/
    /*opacity: 0.5;*/
    /*border-bottom: 300px solid #00d5f4;*/
    /*border-left: 300px solid transparent;*/
    /*right: 0;*/
    /*bottom: 0;*/
    /*z-index: 1;*/
/*}*/

/*.img-teaser__hover-overlay:after {*/
    /*content: '';*/
    /*position: absolute;*/
    /*width: 0;*/
    /*height: 0;*/
    /*border-left: 85px solid transparent;*/
    /*border-right: 85px solid transparent;*/
    /*border-bottom: 85px solid #002b90;*/
    /*opacity: 0.2;*/
    /*bottom: 0;*/
    /*right: 32%;*/
/*}*/

.img-teaser:hover .btn{
    background: #fff;
    color: var(--color-default) !important;
    border-color: transparent;
}

.img-teaser__triangle-overlay{
    position: absolute;
    width: 380px;
    height: 245px;
    bottom:0;
    right: 0;
    opacity: 0;
}

.img-teaser:hover .img-teaser__triangle-overlay{
    opacity: 1;
}


.img-teaser__triangle-overlay:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-color: transparent;
    opacity: 0.5;
    border-bottom: 250px solid #00d5f4;
    border-left: 250px solid transparent;
    right: 0;
    bottom: 0;
    z-index: 2;
}

.img-teaser__triangle-overlay:after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 85px solid transparent;
    border-right: 85px solid transparent;
    border-bottom: 85px solid #002b90;
    opacity: 0.2;
    bottom: 0;
    left:0;
    z-index: 1;
}


.img-teaser__hl{
    font-size: 27px;
    line-height: 34px;
}

.img-teaser__sl{
    font-size: 20px;
}


@media screen and (min-width: 768px){

    .img-teaser__sl{
        font-size: 23px;
    }

    .img-teaser__hl{
        font-size: 40px;
        line-height: 43px;
    }

    .img-teaser__body .btn{
        margin-top: 25px;
    }

    .teaser-grid__vertical-teaser{
        margin-top: 84px;
    }
}