.references-tiles__tile{
    background: #ffffff;
    height: auto;
}
.references-tiles__tile:before{
    transition: all 250ms ease;
    content: '';
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-image: linear-gradient(to top, #00d5f4 0%, #00abe5 100%);
    z-index: 1;
    opacity: 0;
}

.reference-tiles__tile--active:before,
.reference-tiles__tile--active .overlay__close,
.reference-tiles__tile--active .overlay__text {
    opacity: 1;
}
.tile__img{
    padding: 5px;
}

.overlay__text{
    width: 100%;
}

.overlay__close,
.overlay__text {
    opacity: 0;
    color: #ffffff;
}

.overlay__text,
.overlay__close{
    z-index: 1;
}

.overlay__close{
    transform: rotate(45deg);
    position: absolute;
    top: 5px;
    right: 5px;
}

.overlay__hl{
    font-size: 20px;
}

.overlay__sl{
    font-size: 16px;
    line-height: 1.4;
    display: block;
    margin-top: 7px;
}

@media screen and (min-width: 768px){
    .references-tiles__tile{
        height: 207px;
    }

    .tile__img{
        padding: 0;
    }
    .overlay__close{
        transform: rotate(45deg);
        position: absolute;
        top: 25px;
        right: 25px;
    }
    .overlay__hl{
        font-size: 30px;
    }

    .overlay__sl{
        font-size: 20px;
    }
}