.embed-responsive-9by16{
    padding-top: 177.7%;
}

.embed-responsive-1by1{
    padding-top: 100%;
}

.embed-responsive-3by1{
    padding-top: 33.33%;
}

.embed-responsive-7by3{
    padding-top: 42.857%;
}

.embed-responsive-ref-slider-small{
    padding-top: 165%
}