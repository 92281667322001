@media screen and (max-width: 767px) {
    html.has-overflow-hidden, .has-overflow-hidden body {
        overflow: hidden;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .navbar-main {
        top: 0;
        left: 0;
        width: 100%;
        height: 85px;
        padding: 15px;
    }

    .navbar-nav{
        margin: 0;
    }

    /*Toggle Button*/
    .navbar-main .navbar-toggle .icon-bar {
        transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
    }

    .navbar-toggle.is-open {
        background: #fff;
        border-left: none;
        padding: 9px 10.5px;
    }
    .is-open .icon-bar:nth-child(2) {
        opacity: 0;
    }
    .is-open .icon-bar:nth-child(3) {
        transform: rotate(-45deg);
    }
    .is-open .icon-bar:nth-child(4) {
        transform: rotate(45deg) translateX(-9px) translateY(-9px);
    }

    .mobile-nav {
        display: block;
        position: fixed;
        top: 85px;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        padding-top: 0;
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;
        z-index: 99;
        background: #fff;
        border-top: 1px solid #bec1c4;
    }

    .mobile-nav.is-open{
        width: 93%;
        transform: translateX(8%);
    }

    .mobile-nav-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .mobile-nav .navbar-nav>li>a{
        position: relative;
        border-bottom: 1px solid #bec1c4;
        padding: 20px;
        font-size: 22px;
    }

    .mobile-nav .subnav-toggle{
        font-size: 6px;
        position: absolute;
        right: 0px;
        top:0;
        padding: 27px 20px;
    }

    .mobile-nav li.is-open>a>.subnav-toggle{
        transform: rotate(180deg);
    }


    .mobile-nav .navbar-main__dropdown,
    .mobile-nav .navbar-main__dropdown__subnav{
        display: none;
        background-image: linear-gradient(to top, #00d5f4 0%, #00abe5 100%);
    }

    .mobile-nav .navbar-main__dropdown ul>li>a{
        position: relative;
        display: block;
        padding: 13px 20px;
        font-size: 18px;
        color: #fff;
        border-bottom: 1px solid #40d1f2;
    }
    .mobile-nav .navbar-main__dropdown ul>li>a>.icon-arrow-nav{
        padding: 23px 20px;
    }

    .mobile-nav .navbar-main__dropdown__subnav{
        display: none;
    }

    .mobile-nav .navbar-main__dropdown__subnav>ul>li>a{
        padding-left: 35px;
    }
    .mobile-nav .navbar-main__dropdown__subnav>ul>li:last-child>a{
        border-color: #5ddffd;
    }


    .mobile-nav__btn-wrapper{
        padding: 0 20px;
    }

    .mobile-nav__btn-wrapper>a{
        padding: 13px 20px;
        font-size: 20px;
    }
    .mobile-nav__btn-wrapper>a>.icon-phone{
        margin-right: 0;
        font-size: 22px;
    }
    
    
    .mobile-nav__search{
        position: relative;
    }

    .mobile-nav__search .form-control{
        border-color: var(--color-primary);
        padding-right: 118px;
    }

    .navbar-search__btn{
        position: absolute;
        right: 0;
        top: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 17px 20px;
        font-size: 16px;
    }

    .navbar-search__btn.btn-icon .icon {
        font-size: 13px;
        margin-right: 5px;
    }
}
