.contact-item__hl{
    margin-bottom: 10px;
}

.description-line__label,
.description-line--important{
    font-weight: 700;
}
.description-line__label--block{
    display: block;
}
